// @ts-nocheck
/* eslint-disable */
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import styling from "../Home/Home.module.css";
import ASTRONOMY from "../../../assets/images/jr-korpa-9XngoIpxcEo-unsplash(1).jpg";
import CITY from "../../../assets/images/pexels-george-desipris-1121097.jpg";
import FANTASY from "../../../assets/images/pexels-vinicius-vieira-ft-4424355.jpg";
import LION from "../../../assets/images/sandro-katalina-k1bO_VTiZSs-unsplash.jpg";
import ME from "../../../assets/images/Screenshot_20230116-1702272.png";
import { Link } from "react-router-dom";
import Intro from "../Intro/Intro";
import homeCSS from "./../Intro/Intro.module.css";
import CarouselType from "./../../../interfaces/CarouselType.ts";
import { executeCarousel } from "boxparade-carousels-react";

const carousels = [
  "slide-l",
  "slide-r",
  "slide-t",
  "slide-b",
  "fade",
  "book-l",
  "book-r",
  "book-c",
  "book-t",
  "book-b",
  "3d-rotate",
  "3d-rotate-t",
  "3d-rotate-b",
  "spotlight-rotate",
  "spotlight-rotate-t",
  "spotlight-rotate-b",
  "wipe-l",
  "wipe-r",
  "wipe-t",
  "wipe-b",
  "scale",
  "headline",
  "looney-tune",
  "none",
];
const Home = () => {
  const container = useRef();
  const [cIndex, setCIndex] = useState<number>(
    carousels.findIndex((x) => x === "3d-rotate")
  );
  const [carouselID, setCarouselID] = useState<string>(`Carousel${cIndex}`);
  const [lightMode, setLightMode] = useState<boolean>(false);
  const [runBoxWorm, setRunBoxWorm] = useState<boolean>(false);
  const [introActive, setIntroActive] = useState<boolean>(true);
  const isMobile = useMemo<boolean>(() => {
    try {
      return document.createEvent("TouchEvent");
    } catch (e) {
      return false;
    }
  }, []);
  const [showIntro, setShowIntro] = useState<boolean>(
    window.sessionStorage.getItem("showIntro")
  );
  const [data, setData] = useState<CarouselType>({
    id: carouselID,
    type: "3d-rotate",
    automate: false,
    imageScale: "fill",
    cardScale: 1.5,
    performanceMode: true,
    bottomNavButtons: {
      size: 1,
      shape: "round",
    },
    navArrow: {
      size: 2,
    },
    styling: {
      width: "90%",
      linkStyle: {
        backgroundColor: "rgba(0,0,0,.7)",
        color: "#fff",
        textDecoration: "none",
        borderRadius: 30,
        fontFamily: '"Chakra Petch", sans-serif',
        fontSize: 2,
      },
    },
    items: [
      {
        image: ASTRONOMY,
        title: "Boxparade.GraphX Beta",
        link: {
          target: "_parent",
          value: "View Graphing Library",
          link: "https://graphx.boxparade.app",
        },
      },
      {
        image: CITY,
        title: "Boxparade.Mobile",
        link: {
          target: "_parent",
          value: "View Overlay Library Demo",
          link: "https://www.youtube.com/watch?v=EB58DZMHVj4",
        },
      },
      {
        image: FANTASY,
        title: "Boxparade.Dashboards",
      },
      {
        image: LION,
        title: "Boxparade.Launch",
      },
    ],
  });

  const exeCarousel = (i: number) => {
    if (i !== cIndex) {
      const divChildren = container.current.childNodes.length;
      let loop = 0;
      while (loop < divChildren) {
        container.current.removeChild(container.current.childNodes[0]);
        loop++;
      }
    }
    setData((prevState) => {
      return {
        ...prevState,
        type: carousels[i],
        id: `carousel${i}`,
        bottomNavButtons: {
          ...prevState.bottomNavButtons,
          color:
            lightMode &&
            (carousels[i].indexOf("spotlight") > -1 ||
              carousels[i].indexOf("3d") > -1)
              ? "#000"
              : "#fff",
          inactiveColor:
            lightMode &&
            (carousels[i].indexOf("spotlight") > -1 ||
              carousels[i].indexOf("3d") > -1)
              ? "rgba(0,0,0,0.1)"
              : "rgba(255,255,255,0.1)",
        },
        navArrow: {
          ...prevState.navArrow,
          backgroundColor:
            lightMode &&
            (carousels[i].indexOf("spotlight") > -1 ||
              carousels[i].indexOf("3d") > -1)
              ? "#000"
              : "#fff",
        },
      };
    });
    setCarouselID(`carousel${i}`);
    setCIndex(i);
  };

  useEffect(() => {
    executeCarousel(data);
  }, [data?.type, data?.bottomNavButtons?.color, lightMode]);

  useEffect(() => {
    setTimeout(() => {
      setIntroActive(false);
      setShowIntro(false);
    }, 1500);
  }, []);

  useLayoutEffect(() => {
    const effArray = ["shadow-draw", "wave", "draw", "slide-l-draw"];
    bxpwriter(
      "animText",
      2500,
      effArray[Math.floor(Math.random() * effArray.length)]
    );
  }, []);

  useEffect(() => {
    if (!showIntro) window.sessionStorage.clear();
  }, [showIntro]);

  const exeBoxWorm = useCallback((e, t = "boxworm") => {
    let l = "right";
    e = e ?? "canvas";
    var $,
      i = [],
      f = [],
      n = [],
      _ = [],
      o = 12,
      a = 12,
      r = !0,
      h = !1,
      c = !1;
    let g = document.getElementById(e);
    g.setAttribute("width", 720), g.setAttribute("height", 408);
    let s = g?.getBoundingClientRect().width,
      x = g?.getBoundingClientRect().height;
    var y = {
        x: s / 2 - ((s / 2 - 0.2 * s) * 0.65) / 2,
        y: x / 2 - 0.05 * x + 0.03 * s,
        w: (s / 2 - 0.2 * s) * 0.65,
        h: (x / 2 - 0.2 * x) * 0.35,
      },
      u = { x: 289.8, y: 205.2, w: 140.4, h: 42.839999999999996 };
    let d = g.getContext("2d");
    if ("boxrun" === t.toLowerCase().trim());
    else {
      let R = 0,
        b = Math.floor(60);
      for (; R < b; ) f.push(12 * R), R++;
      R = 0;
      let p = Math.floor(33);
      for (; R < p; ) n.push(12 * R + 12), R++;
      let T = !1;
      function w() {
        if (g.getContext) {
          if (
            (T && d.clearRect(0, 12, g.width, g.height - 12),
            o < 0 || a < 12 || o > 708 || a > 396)
          ) {
            k();
            return;
          }
          let e = 1;
          for (; e < _.length; ) {
            if (_[0].x0 === _[e].x0 && _[0].y0 === _[e].y0) {
              k();
              return;
            }
            e++;
          }
          for (e = 0; e < i.length; )
            o === f[i[e].x] &&
              a === n[i[e].y] &&
              (_.push({ ..._[_.length - 1] }),
              d.clearRect(0, 12, f[i[e].x], n[i[e].y]),
              d.clearRect(0, 0, 720, 12),
              d.beginPath(),
              (d.fillStyle = "#da23e8"),
              d.fillRect(0, 0, 720, 12),
              d.fill(),
              d.beginPath(),
              (d.fillStyle = "#fff"),
              (d.font = "9.600000000000001px Arial"),
              d.fillText(
                `Score: ${_.length - 1 < 0 ? 0 : _.length - 1}`,
                12,
                9
              ),
              d.fill(),
              (i = []),
              A(),
              (h = !0)),
              e++;
          let t = 0 === _.length ? 0 : _.length - 1;
          do {
            let l = 0 === t ? o : _[t - 1].x0,
              $ = 0 === t ? 12 + o : _[t - 1].x1,
              c = 0 === t ? a : _[t - 1].y0,
              s = 0 === t ? 12 + a : _[t - 1].y1;
            d.beginPath(),
              d.moveTo(l, c),
              d.lineTo($, c),
              d.lineTo($, s),
              d.lineTo(l, s),
              (d.fillStyle = "#ff42f9"),
              d.fill(),
              0 === t
                ? _.splice(t, 1, { x0: l, y0: c, x1: $, y1: s })
                : (h ||
                    _.splice(t, 1, {
                      x0: _[t - 1].x0,
                      y0: _[t - 1].y0,
                      x1: _[t - 1].x1,
                      y1: _[t - 1].y1,
                    }),
                  h && (h = !1)),
              t--;
          } while (t > -1);
          r || A(!0), (T = !0);
        }
      }
      function S(t) {
        g = document.getElementById(e);
        let $ = t.pageX - g.offsetLeft,
          f = t.pageY - g.offsetTop;
        t.touches &&
          t.touches[0] &&
          (($ = t.touches[0].pageX - g.offsetLeft),
          (f = t.touches[0].pageY - g.offsetTop)),
          c &&
            $ >= y.x &&
            $ <= y.x + y.w &&
            f >= y.y &&
            f <= y.y + y.h &&
            (d.clearRect(0, 0, g.width, g.height - 12),
            (l = "right"),
            (r = !0),
            (i = []),
            (c = !1),
            P());
      }
      function A(e = !1) {
        let t = 0;
        for (; t < 1; ) {
          let l = e ? i[t].x : Math.floor(Math.random() * (b - 1)),
            $ = e ? i[t].y : Math.floor(Math.random() * (p - 1));
          d.beginPath(),
            d.moveTo(f[l], n[$]),
            d.lineTo(f[l] + 12, n[$]),
            d.lineTo(f[l] + 12, n[$] + 12),
            d.lineTo(f[l], n[$] + 12),
            (d.fillStyle = "#da23e8"),
            d.fill(),
            e || i.push({ x: l, y: $ }),
            t++;
        }
      }
      function k() {
        clearInterval($),
          (o = 12),
          (a = 12),
          (_ = []),
          (l = "right"),
          d.beginPath(),
          (d.fillStyle = "#da23e8"),
          d.fillRect(216, 122.39999999999999, 288, 163.20000000000002, 7.2),
          d.fill(),
          d.beginPath(),
          (d.font = "21.599999999999998px Arial"),
          (d.textAlign = "center"),
          (d.fillStyle = "#fff"),
          d.fillText("GAME OVER", 360, 183.6, 288),
          d.fill(),
          d.beginPath(),
          (d.strokeStyle = "#f1f1f1"),
          d.strokeRect(u.x, u.y, u.w, u.h),
          d.stroke(),
          d.beginPath(),
          (d.fillStyle = "#da23e8"),
          d.fillRect(u.x, u.y, u.w, u.h),
          d.fill(),
          d.beginPath(),
          (d.font = "18px Arial"),
          (d.fillStyle = "#fff"),
          d.fillText("START OVER", 360, 232.61999999999998, 288),
          d.fill(),
          (c = !0);
      }
      function P() {
        d.beginPath(),
          (d.fillStyle = "#da23e8"),
          d.fillRect(0, 0, 720, 12),
          d.lineTo(720, 12),
          d.fill(),
          d.beginPath(),
          (d.font = "9.600000000000001px Arial"),
          (d.fillStyle = "#fff"),
          (d.textAlign = "left"),
          d.fillText(`Score: ${_.length - 1 < 0 ? 0 : _.length - 1}`, 12, 9),
          d.fill(),
          w(),
          r && A(),
          ($ = setInterval(function () {
            switch (l) {
              case "right":
              default:
                o += 12;
                break;
              case "left":
                o -= 12;
                break;
              case "up":
                a -= 12;
                break;
              case "down":
                a += 12;
            }
            w();
          }, 120)),
          (r = !1);
      }
      g.addEventListener("click", function (e) {
        S(e);
      }),
        g.addEventListener("touchstart", function (e) {
          S(e);
        }),
        (document.onkeydown = function (e) {
          switch (e.key.toString().toUpperCase()) {
            case "ARROWLEFT":
              l = "left";
              break;
            case "ARROWRIGHT":
            default:
              l = "right";
              break;
            case "ARROWUP":
              l = "up";
              break;
            case "ARROWDOWN":
              l = "down";
          }
        }),
        P();
    }
  }, []);

  useEffect(() => {
    if (runBoxWorm) exeBoxWorm("boxworm");
  }, [runBoxWorm]);

  const lightModeToggle = (lightModeSet: boolean) => {
    const divChildren = container.current.childNodes.length;
    let loop = 0;
    while (loop < divChildren) {
      container.current.removeChild(container.current.childNodes[0]);
      loop++;
    }
    setData((prevState) => {
      return {
        ...prevState,
        bottomNavButtons: {
          ...prevState.bottomNavButtons,
          color:
            lightModeSet &&
            (data?.type.toLowerCase().indexOf("spotlight") > -1 ||
              data?.type.toLowerCase().indexOf("3d") > -1)
              ? "#000"
              : "#fff",
          inactiveColor:
            lightModeSet &&
            (data?.type.toLowerCase().indexOf("spotlight") > -1 ||
              data?.type.toLowerCase().indexOf("3d") > -1)
              ? "rgba(0,0,0,0.1)"
              : "rgba(255,255,255,0.1)",
        },
        navArrow: {
          ...prevState.navArrow,
          backgroundColor:
            lightModeSet &&
            (data?.type.toLowerCase().indexOf("spotlight") > -1 ||
              data?.type.toLowerCase().indexOf("3d") > -1)
              ? "#000"
              : "#fff",
        },
      };
    });
    setLightMode((prevState) => !prevState);
  };
  const bxpwriter = useCallback((e, t = 500, a = "shadow") => {
    let l = document.getElementsByClassName((e ?? "").toString()),
      s = document.createElement("style"),
      r = document.createTextNode(
        `.${e}{overflow-wrap:break-word;}.${e} span{display:inline-block !important;position:relative;}`
      );
    s.appendChild(r);
    let n = document.getElementsByTagName("head")[0];
    if ((n.appendChild(s), l.length > 0))
      for (let o = 0; o < l.length; o++) {
        let i;
        l[o].childNodes.forEach((e, s, r) => {
          (i = i || (e?.data?.split("") ?? "")),
            l[o].removeChild(l[o].childNodes[s]),
            Array.isArray(i) &&
              i.forEach((e, s) => {
                let r = document.createElement("span"),
                  n = document.createTextNode(" " === e ? "\xa0" : e);
                switch ((r.appendChild(n), a)) {
                  case "wave":
                    (r.style.transform = "translate(0px,-30px)"),
                      (r.style.opacity = 0);
                    break;
                  case "draw":
                    (r.style.transform = "translate(0px,0px)"),
                      (r.style.opacity = 0);
                    break;
                  case "slide-l":
                  case "slide-l-draw":
                    (r.style.transform = "translate(-30px,0px)"),
                      (r.style.opacity = 0);
                    break;
                  default:
                    (r.style.transform = `translate(${Math.floor(
                      30 * Math.random()
                    )}px,${Math.floor(30 * Math.random())}px)`),
                      (r.style.opacity = 0);
                }
                (r.style.transition = `all ${t}ms ease ${
                  ["shadow-draw", "wave", "draw", "slide-l-draw"].includes(
                    a.toLowerCase().trim()
                  )
                    ? (t / i.length) * s
                    : 0
                }ms`),
                  setTimeout(() => {
                    "" === a ||
                      ((r.style.transform = "translate(0px,0px)"),
                      (r.style.opacity = 1));
                  }, 15),
                  setTimeout(
                    () => {
                      (r.style.transition = ""),
                        (r.style.transform = ""),
                        (r.style.opacity = ""),
                        "" === r.style.cssText && r.removeAttribute("style"),
                        "&nbsp;" === r.innerHTML && (r.innerHTML = " "),
                        l[o] &&
                          r.parentElement == l[o] &&
                          l[o].replaceChild(r.firstChild, r);
                    },
                    ["shadow-draw", "wave", "draw", "slide-l-draw"].includes(
                      a.toLowerCase().trim()
                    )
                      ? t + (t / i.length) * s
                      : t
                  ),
                  l[o].insertBefore(r, l[o].childNodes[s]);
              });
        });
      }
  }, []);

  return (
    <>
      {introActive && showIntro && (
        <div className={`${homeCSS.main} ${styling.fadeOut}`}>
          <Intro />
        </div>
      )}
      <main
        className={`${styling.main} ${lightMode && styling.lightModeBg}`}
        style={{ overflow: runBoxWorm ? "hidden" : "auto" }}
      >
        <div>
          <div>
            <Link to="/" className={`${lightMode && styling.lightModeText}`}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  viewBox="0 0 150 150"
                >
                  <path
                    stroke="none"
                    fill="#da23e8"
                    d="M0, 0 L0, 55 L20, 55 L20, 20 L55, 20 L55, 0 Z M150,0 L150, 55 L130, 55 L130, 20 L95, 20 L95, 0 Z M150,150 L95, 150 L95,130 L130, 130 L130, 95 L150, 95 Z M0,150 L0, 95 L20,95 L20, 130 L55, 130 L55, 150 Z M100, 30 L120, 30 L120, 50 L100, 50 Z M30, 100 L50, 100 L50, 120 L30, 120 Z M53, 96 L53, 53 L96, 53 L96, 96 Z"
                  ></path>
                </svg>
              </span>
              <span>B</span>
              <span>O</span>
              <span>X</span>
              <span>P</span>
              <span>A</span>
              <span>R</span>
              <span>A</span>
              <span>D</span>
              <span>E</span>
              <span>&copy;</span>
            </Link>
            <div>
              <button
                id={styling.modeButton}
                onClick={() => {
                  lightModeToggle(!lightMode);
                }}
              >
                {lightMode && (
                  <svg
                    className={styling.sunAnim}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z" />
                  </svg>
                )}
                {!lightMode && (
                  <svg
                    className={styling.moonAnim}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path
                      fill="#fff"
                      d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM80-440q-17 0-28.5-11.5T40-480q0-17 11.5-28.5T80-520h80q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440H80Zm720 0q-17 0-28.5-11.5T760-480q0-17 11.5-28.5T800-520h80q17 0 28.5 11.5T920-480q0 17-11.5 28.5T880-440h-80ZM480-760q-17 0-28.5-11.5T440-800v-80q0-17 11.5-28.5T480-920q17 0 28.5 11.5T520-880v80q0 17-11.5 28.5T480-760Zm0 720q-17 0-28.5-11.5T440-80v-80q0-17 11.5-28.5T480-200q17 0 28.5 11.5T520-160v80q0 17-11.5 28.5T480-40ZM226-678l-43-42q-12-11-11.5-28t11.5-29q12-12 29-12t28 12l42 43q11 12 11 28t-11 28q-11 12-27.5 11.5T226-678Zm494 495-42-43q-11-12-11-28.5t11-27.5q11-12 27.5-11.5T734-282l43 42q12 11 11.5 28T777-183q-12 12-29 12t-28-12Zm-42-495q-12-11-11.5-27.5T678-734l42-43q11-12 28-11.5t29 11.5q12 12 12 29t-12 28l-43 42q-12 11-28 11t-28-11ZM183-183q-12-12-12-29t12-28l43-42q12-11 28.5-11t27.5 11q12 11 11.5 27.5T282-226l-42 43q-11 12-28 11.5T183-183Zm297-297Z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className={styling.homeTopContainer}>
            <div>
              <img src={ME} alt="TRAYVON_COMO_IMAGE" />
            </div>
            <p
              className={`animText ${styling.headline} ${
                lightMode && styling.lightModeText
              }`}
            >
              Welcome to BOXPARADE a website designed to showcase different
              projects and ideas created by me (Trayvon Como). I can build
              anything from React websites, Angular websites, Business
              Intelligence Software, Automated Chat Bots, NPM packages, Custom
              Software, etc.
            </p>
          </div>
          <a
            rel="noopener"
            href="https://www.npmjs.com/package/boxparade-carousels-react?activeTab=readme"
            target="_blank"
            className={`${styling.roundBtn} ${styling.npmBtn} ${
              lightMode && styling.npmLightModeBtn
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70%"
              viewBox="0 0 256 100"
              preserveAspectRatio="xMinYMin meet"
            >
              <path
                d="M0 0v85.498h71.166V99.83H128V85.498h128V0H0z"
                fill="#CB3837"
              />
              <path
                d="M42.502 14.332h-28.17v56.834h28.17V28.664h14.332v42.502h14.332V14.332H42.502zM85.498 14.332v71.166h28.664V71.166h28.17V14.332H85.498zM128 56.834h-13.838v-28.17H128v28.17zM184.834 14.332h-28.17v56.834h28.17V28.664h14.332v42.502h14.332V28.664h14.332v42.502h14.332V14.332h-57.328z"
                fill="#FFF"
              />
            </svg>
          </a>
          <div
            id={carouselID}
            className={styling.carouselStyle}
            ref={container}
          />
          <div className={styling.buttonContainer}>
            {carousels.map((item, i) => {
              return (
                <button
                  key={item}
                  className={i === cIndex ? styling.activeCButton : ""}
                  onClick={() => {
                    exeCarousel(i);
                  }}
                >
                  <span />
                  {item}
                  <span />
                </button>
              );
            })}
          </div>
          <button
            className={`${styling.roundBtn} ${
              lightMode ? styling.lightModeBtn : ""
            }`}
            onClick={() => {
              setRunBoxWorm((prevState) => !prevState);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30"
              viewBox="0 -960 960 960"
              width="30"
            >
              <path
                fill="#fff"
                d="M182-200q-51 0-79-35.5T82-322l42-300q9-60 53.5-99T282-760h396q60 0 104.5 39t53.5 99l42 300q7 51-21 86.5T778-200q-21 0-39-7.5T706-230l-90-90H344l-90 90q-15 15-33 22.5t-39 7.5Zm16-86 114-114h336l114 114q2 2 16 6 11 0 17.5-6.5T800-304l-44-308q-4-29-26-48.5T678-680H282q-30 0-52 19.5T204-612l-44 308q-2 11 4.5 17.5T182-280q2 0 16-6Zm482-154q17 0 28.5-11.5T720-480q0-17-11.5-28.5T680-520q-17 0-28.5 11.5T640-480q0 17 11.5 28.5T680-440Zm-80-120q17 0 28.5-11.5T640-600q0-17-11.5-28.5T600-640q-17 0-28.5 11.5T560-600q0 17 11.5 28.5T600-560ZM310-440h60v-70h70v-60h-70v-70h-60v70h-70v60h70v70Zm170-40Z"
              />
            </svg>
          </button>
          {/* <span
            className={`${styling.info} ${lightMode && styling.lightModeText}`}
          >
            Just a few more upgradesss..
          </span> */}
        </div>
        {runBoxWorm &&
          ReactDOM.createPortal(
            <div
              id={styling.boxwormContainer}
              className={`${lightMode && styling.lightModeBgLight}`}
            >
              <button
                className={`${styling.roundBtn} ${styling.exitBtn} ${
                  lightMode ? styling.lightModeBtn : ""
                }`}
                onClick={() => {
                  setRunBoxWorm(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path
                    fill="#fff"
                    d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
                  />
                </svg>
              </button>
              <canvas id="boxworm" className={styling.boxworm}></canvas>
              <div>
                {isMobile && (
                  <div
                    className={`${styling.arrowContainer} ${
                      lightMode ? styling.lightArrowContainer : ""
                    }`}
                  >
                    <div>
                      <button
                        onClick={() => {
                          var event = new KeyboardEvent("keydown", {
                            key: "ArrowUp",
                          });
                          document.dispatchEvent(event);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="100%"
                          viewBox="0 -960 960 960"
                          width="100%"
                        >
                          <path d="M440-80v-647L256-544l-56-56 280-280 280 280-56 57-184-184v647h-80Z" />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          var event = new KeyboardEvent("keydown", {
                            key: "ArrowLeft",
                          });
                          document.dispatchEvent(event);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="100%"
                          viewBox="0 -960 960 960"
                          width="100%"
                        >
                          <path d="M360-200 80-480l280-280 56 56-183 184h647v80H233l184 184-57 56Z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          var event = new KeyboardEvent("keydown", {
                            key: "ArrowDown",
                          });
                          document.dispatchEvent(event);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="100%"
                          viewBox="0 -960 960 960"
                          width="100%"
                        >
                          <path d="M480-80 200-360l56-56 184 183v-647h80v647l184-184 56 57L480-80Z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          var event = new KeyboardEvent("keydown", {
                            key: "ArrowRight",
                          });
                          document.dispatchEvent(event);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="100%"
                          viewBox="0 -960 960 960"
                          width="100%"
                        >
                          <path d="m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>,
            document.getElementById("overlay")
          )}
      </main>
    </>
  );
};

export default Home;
