import { useEffect, useState } from "react";
import homeCSS from "./Intro.module.css";
import { useNavigate } from "react-router-dom";

const Intro = () => {
  const [enterActive, setEnterActive] = useState<boolean>(false);
  const [navToMain, setNavToMain] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("keypress", (e: KeyboardEvent) => {
      if (e.key.toString().toUpperCase().trim() === "ENTER") {
        setEnterActive(true);
        triggerMainPage();
      }
    });
  }, []);

  const triggerMainPage = () => {
    window.sessionStorage.setItem("showIntro", "true");
    navigate("/home", {
      state: {
        showIntro: true,
      },
    });
  };

  return (
    <main className={homeCSS.main}>
      <div style={{ minHeight: window.screen.availHeight * 0.5 + "px" }}>
        <div>
          <h1>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 150 150"
              >
                <path
                  stroke="none"
                  fill="#da23e8"
                  d="M0, 0 L0, 55 L20, 55 L20, 20 L55, 20 L55, 0 Z M150,0 L150, 55 L130, 55 L130, 20 L95, 20 L95, 0 Z M150,150 L95, 150 L95,130 L130, 130 L130, 95 L150, 95 Z M0,150 L0, 95 L20,95 L20, 130 L55, 130 L55, 150 Z M100, 30 L120, 30 L120, 50 L100, 50 Z M30, 100 L50, 100 L50, 120 L30, 120 Z M53, 96 L53, 53 L96, 53 L96, 96 Z"
                ></path>
              </svg>
            </span>
            <span>B</span>
            <span>O</span>
            <span>X</span>
            <span>P</span>
            <span>A</span>
            <span>R</span>
            <span>A</span>
            <span>D</span>
            <span>E</span>
            <span>&copy;</span>
          </h1>
          <button
            onClick={triggerMainPage}
            className={enterActive ? "enterActive" : undefined}
          >
            <span />
            Enter
            <span />
          </button>
        </div>
        <span>
          {new Date().getFullYear()} Boxparade<small>&copy;</small> | All Rights
          Reserved
        </span>
      </div>
      <div>
        <div />
        <div />
        <div />
      </div>
    </main>
  );
};

export default Intro;
